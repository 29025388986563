import React, {useState} from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import PageFooter from '../components/PageFooter';
import {Link, useStaticQuery} from "gatsby";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import WarningIcon from '@material-ui/icons/Warning';
import {Offline} from "react-detect-offline";
import Chip from "@material-ui/core/Chip";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import '../fontawesome';
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        "&:after": {
            borderBottom: "2px solid #38C6D9"
        }
    },
    label: {
        "&$focused": {
            color: "#38C6D9"
        }
    },
    focused: {}
});


const IndexPage = () => {
    const [search, setSearch] = useState('');
    const classes = useStyles();

    const data = useStaticQuery(graphql`
        query MyQuery {
          allFile {
            nodes {
              relativePath
            }
          }
        }
      `);
    const relativePaths = data.allFile.nodes
        .map(e => e.relativePath.replace(/\[NanoKarrin]\s/, '')
            .replace(/ /g, '_'))
        .filter(e => e.toLowerCase().includes(search.toLowerCase()))
        .sort();
    return (
        <Layout>
            <section id="main">
                <header>
                    <h1>Piosenkowo NanoKarrin</h1>
                </header>
                <Offline>
                    <Chip
                        icon={<WarningIcon />}
                        label="Uwaga, jesteś offline!"
                        color="secondary"
                    />
                </Offline>
                <Grid container style={{textAlign: 'left'}}>
                    <Grid item xs={12}>
                        <TextField label="Wyszukaj tytuł"
                                   InputLabelProps={{classes: {root: classes.label, focused: classes.focused}}}
                                   InputProps={{classes: {root: classes.root, focuses: classes.focused}}}
                                   onChange={e => setSearch(e.target.value)} style={{width: "100%"}}/>
                    </Grid>
                </Grid>

                <PerfectScrollbar>
                    <Grid container style={{textAlign: 'left', maxHeight: "250px"}}>
                        {relativePaths.map(p => <Grid item xs={12}>
                            <i className='fas fa-music'/>{' '}
                            <Link to={`/${p}`}>{p.replace(/_/g, ' ')}</Link>
                        </Grid>)}
                    </Grid>
                </PerfectScrollbar>
                <Footer />
            </section>
            <PageFooter />
        </Layout>
    );
};

export default IndexPage;
